import './RecoverPasswordContent.less'

import { Link, navigate } from 'gatsby-plugin-react-intl'
import React from 'react'

import { useScopedIntl } from '../../../hooks'
import { AccountType, requestPasswordChange } from '../../../requests'
import { routes } from '../../../routes'
import { DatacMessage, DatacSubtitle, DatacTitle } from '../../common'
import { RecoverPasswordForm, RecoverPasswordFormData } from '../RecoverPasswordForm'

export const RecoverPasswordContent: React.FC<{ accountType: AccountType }> = ({ accountType }) => {
  const intlRecoverPassword = useScopedIntl('auth.recover_password')

  const onFormSubmit = (data: RecoverPasswordFormData) =>
    new Promise<void>(resolve =>
      requestPasswordChange(
        { email: data.email, accountType },
        {
          onSuccess: () => {
            navigate(routes.recoverPasswordSuccess(accountType), { state: { email: data.email } })
            resolve()
          },
          onRequestError: () => {
            DatacMessage.error(
              intlRecoverPassword('request_error.title'),
              intlRecoverPassword('request_error.subtitle')
            )
            resolve()
          }
        }
      )
    )

  return (
    <div className="recover-password-content">
      <DatacTitle type="h1" className="recover-password-content__title">
        {intlRecoverPassword('title')}
      </DatacTitle>
      <DatacSubtitle type="h2" className="recover-password-content__subtitle">
        {intlRecoverPassword('subtitle')}
      </DatacSubtitle>
      <RecoverPasswordForm onSubmit={onFormSubmit} />
      <div className="recover-password-content__cancel">
        <Link to={routes.signIn(accountType)}>{intlRecoverPassword('cancel')}</Link>
      </div>
    </div>
  )
}
