import './FormInputWrapper.less'

import React from 'react'

interface Props {
  children: React.ReactNode
  label: string
}

export const FormInputWrapper: React.FC<Props> = ({ children, label }) => {
  return (
    <div className="auth-form-input-wrapper">
      {children}
      <span className="auth-form-input-wrapper__label">{label}</span>
    </div>
  )
}
