import './RecoverPasswordForm.less'

import { Form, Input } from 'antd'
import { useIntl } from 'gatsby-plugin-react-intl'
import React from 'react'

import { useSubmit } from '../../../hooks'
import { FormInputWrapper } from '../FormInputWrapper'
import { FormSubmitButton } from '../FormSubmitButton'

export interface RecoverPasswordFormData {
  email: string
}

interface Props {
  onSubmit: (data: RecoverPasswordFormData) => Promise<void>
}

export const RecoverPasswordForm: React.FC<Props> = ({ onSubmit }) => {
  const intl = useIntl()
  const intlField = (scopedId: string) => intl.formatMessage({ id: `auth.signup.field.${scopedId}` })
  const [isSubmitOngoing, onFormSubmit] = useSubmit<RecoverPasswordFormData>(onSubmit)

  return (
    <div className="recover-password-form">
      <Form name="recoverPasswordForm" onFinish={data => onFormSubmit(data as RecoverPasswordFormData)}>
        <FormInputWrapper label={intlField('email.label')}>
          <Form.Item
            name="email"
            validateFirst
            rules={[
              {
                required: true,
                whitespace: true,
                message: intlField('email.validation.required')
              },
              {
                type: 'email',
                message: intlField('email.validation.email')
              }
            ]}
          >
            <Input className="auth-form-input" size="large" placeholder={intlField('email.placeholder')} />
          </Form.Item>
        </FormInputWrapper>

        <Form.Item>
          <FormSubmitButton
            className="recover-password-form__submit"
            label={intl.formatMessage({ id: 'auth.recover_password.submit' })}
            loading={isSubmitOngoing}
          />
        </Form.Item>
      </Form>
    </div>
  )
}
